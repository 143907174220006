export const typeScale = {
    headerOne: '11.089rem',
    headerTwo: '6.854rem',
    headerThree: '4.236rem',
    headerFour: '2.618rem',
    headerFive: '1.618rem',
    paragraph: '1rem',
    subText: '0.618emr',
    small: '0.382rem',
}
